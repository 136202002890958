import * as React from "react"
import { PageProps } from "gatsby"
import Seo from "../components/seo"
import GlobalFonts from "../fonts/fonts"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"

import FreeAccountSection from "../sections/FreeAccountSection"
import CashbackSection from "../sections/CashbackSection"
import FreeTransactionsSection from "../sections/FreeTransactionsSection"
import PayBillsAtHomeSection from "../sections/PayBillsAtHomeSection"
import CardSection from "../sections/CardSection"
import PartnersSection from "../components/PartnersSection"

const IndexPage: React.FC<PageProps> = ({ location }) => (
  <>
    <Seo title="DieselBank - Conta das Famílias Caminhoneiras" />
    <GlobalFonts />
    <Navbar location={location} />
    <FreeAccountSection />
    <CashbackSection />
    <FreeTransactionsSection />
    <PayBillsAtHomeSection />
    <CardSection />
    <PartnersSection />
    <Footer />
  </>
)

export default IndexPage
