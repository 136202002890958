import React from 'react';
import styled from 'styled-components';
import Section from '../components/Section';
import Card from '../images/card.png';
import { TypographyLarge } from '../components/Typography';

const Container = styled.div`
  max-width: 640px;
  display: flex;
  align-items: center;
  height: 100%;
  text-align: right;
  margin-left: auto;
  margin-right: 128px;
  @media(max-width: 800px){
    margin-left: 24px;
    margin-right: 24px;
    padding-top: 64px;
    padding-bottom: 64px;
    text-align: center;
  }
`;

const ContentWrapper = styled.div`

`;

const CardSection = () => (
  <Section imgUrl={Card}>
    <Container>
      <ContentWrapper>
        <TypographyLarge>
          Um cartão aceito em estabelecimentos de todo o Brasil.
        </TypographyLarge>
      </ContentWrapper>
    </Container>
  </Section>
);

export default CardSection;
