import React from "react"
import styled from "styled-components"
import showing from "../images/home.jpg"
import { TypographyLarge } from "../components/Typography"
import Button from "../components/Button"
import Section from "../components/Section"

const Container = styled.div`
  max-width: 720px;
  margin-left: 128px;
  position: absolute;
  bottom: 64px;
  @media (max-width: 800px) {
    width: calc(100% - 48px);
    position: static;
    max-width: none;
    margin-right: 24px;
    margin-left: 24px;
    text-align: center;
    padding-top: 64px;
    padding-bottom: 64px;
  }
`

const FreeAccountSection: React.FC = () => {
  const isApple =
    typeof window !== "undefined"
      ? window.navigator.userAgent.toLowerCase().indexOf("iphone") > -1 ||
        navigator.userAgent.toLowerCase().indexOf("macintosh") > -1
      : false

  const appStoreLink =
    "https://apps.apple.com/br/app/dieselbank-para-caminhoneiros/id1516013322"
  const playStoreLink =
    "https://play.google.com/store/apps/details?id=com.test.dieselbank&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"

  return (
    <Section imgUrl={showing}>
      <Container>
        <TypographyLarge>
          A conta gratuita pensada para famílias caminhoneiras
        </TypographyLarge>

        <Button
          onClick={() => {
            typeof window !== "undefined" &&
              window.gtag("event", "cta_mobile_stores", {
                device: isApple ? "Apple" : "Android",
                link: isApple ? appStoreLink : playStoreLink,
              })
            location.href = isApple ? appStoreLink : playStoreLink
          }}
        >
          Abra sua conta agora
        </Button>
      </Container>
    </Section>
  )
}

export default FreeAccountSection
