import React from 'react';
import styled from 'styled-components';
import Section from '../components/Section';
import pixKeys from '../images/pix-keys.png';
import { TypographyLarge, TypographyMedium } from '../components/Typography';

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  max-width: 640px;
  text-align: right;
  margin-left: auto;
  margin-right: 128px;
  @media(max-width: 800px){
    margin-right: 24px;
    margin-left: 24px;
    padding-top: 64px;
    padding-bottom: 64px;
    text-align: center;
  }
`;

const ContentWrapper = styled.div`
`;

const FreeTransactionsSection = () => (
  <Section imgUrl={pixKeys}>
    <Container>
      <ContentWrapper>
        <TypographyLarge>
          Um jeito seguro e gratuito para transferir dinheiro de onde você estiver.
        </TypographyLarge>
        <TypographyMedium>
          Com Pix, seu dinheiro cai em poucos segundos a qualquer hora do dia.
        </TypographyMedium>
      </ContentWrapper>
    </Container>
  </Section>
);

export default FreeTransactionsSection;
