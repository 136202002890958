import React, { useState } from "react"
import styled from "styled-components"
import Section from "../components/Section"

import { TypographyLarge, TypographyCaption } from "../components/Typography"

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 128px;
  margin-left: 128px;
  max-width: 100%;
  @media (max-width: 800px) {
    margin-right: 24px;
    margin-left: 24px;
    padding-top: 64px;
    padding-bottom: 64px;
    justify-content: center;
    text-align: center;
  }
`

// excess height to improve interactive area / accessibility
const height = "12px"
const thumbHeight = 12
const trackHeight = "12px"

// colours
const upperColor = "#edf5f9"
const lowerColor = "#FF5C50"
const thumbColor = "#ddd"
const thumbHoverColor = "#ccc"
const upperBackground = `linear-gradient(to bottom, ${upperColor}, ${upperColor}) 100% 50% / 100% ${trackHeight} no-repeat transparent`
const lowerBackground = `linear-gradient(to bottom, ${lowerColor}, ${lowerColor}) 100% 50% / 100% ${trackHeight} no-repeat transparent`

const Range = styled.input`
  -webkit-appearance: none;
  margin: 10px 0;
  max-width: 700px;
  width: 100%;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    height: ${height};
    background: #ddd;
    border-width: 1px;
    border-radius: 25px;
  }

  &::-webkit-slider-thumb {
    appearance: none;
    height: ${thumbHeight}px;
    width: ${thumbHeight}px;
    background: ${thumbColor};
    border-radius: 100%;
    border: 12px solid #ff5c50;
    transform: translateY(-30%);
    transition: background-color 150ms;
  }

  &::-moz-range-track,
  &::-moz-range-progress {
    height: ${height};
    background: ${upperBackground};
    border-radius: 8px;
  }

  &::-moz-range-progress {
    background: ${lowerBackground};
    border-radius: 8px;
  }

  &::-moz-range-thumb {
    appearance: none;
    margin: 0;
    height: ${thumbHeight};
    width: ${thumbHeight};
    background: ${thumbColor};
    border-radius: 100%;
    border: 8px;
    transition: background-color 150ms;
  }

  &::-ms-track {
    height: ${height};
    border-radius: 8px;
    /* color needed to hide track marks */
    color: transparent;
    background: transparent;
  }

  &::-ms-fill-lower {
    background: ${lowerBackground};
    border-radius: 8px;
  }

  &::-ms-fill-upper {
    background: ${upperBackground};
    border-radius: 8px;
  }

  &::-ms-thumb {
    appearance: none;
    height: ${thumbHeight};
    width: ${thumbHeight};
    background: ${thumbColor};
    border-radius: 100%;
    border: 8px;
    border-color: #ff5c50;
    transition: background-color 150ms;
    /* IE Edge thinks it can support -webkit prefixes */
    top: 0;
    margin: 0;
    box-shadow: none;
  }

  &:hover,
  &:focus {
    &::-webkit-slider-thumb {
      background-color: ${thumbHoverColor};
    }
    &::-moz-range-thumb {
      background-color: ${thumbHoverColor};
    }
    &::-ms-thumb {
      background-color: ${thumbHoverColor};
    }
  }
`

const ContentWrapper = styled.div`
  @media (max-width: 800px) {
    width: 100%;
  }
`

const Caption = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 700px;
`

const CashbackSection = () => {
  const [rangeValue, setRangeValue] = useState<number>(5000)

  return (
    <Section>
      <Container>
        <ContentWrapper>
          <TypographyLarge black>
            <span style={{ color: "#FF5C50" }}>Ganhe de volta </span>
            até{" "}
            <span style={{ color: "#FF5C50" }}>
              {(0.015 * rangeValue).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })}
            </span>
            <br />
            abastecendo{" "}
            {rangeValue.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })}
            <br />
            em postos parceiros
          </TypographyLarge>
          <Range
            type="range"
            min="10"
            max="10000"
            value={rangeValue}
            onChange={event => setRangeValue(parseInt(event.target.value, 10))}
          />
          <Caption>
            <TypographyCaption black>R$ 10,00</TypographyCaption>
            <TypographyCaption black>R$ 10.000,00</TypographyCaption>
          </Caption>
        </ContentWrapper>
      </Container>
    </Section>
  )
}

export default CashbackSection
